.rodimus-complex-front.list-horizontal {
  margin-bottom: var(--brick-space-deckYGapL);
}

.rodimus-complex-front .wrap-horizontal {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--brick-space-deckYGapL);
}

.rodimus-complex-front .wrap-horizontal-space {
  padding: var(--brick-space-x2);
}
.rodimus-complex-front .wrap-horizontal-space.solo-group {
  padding: 0;
}

@media screen and (max-width: 532px) {
  .rodimus-complex-front.list-horizontal {
    margin-bottom: var(--brick-space-deckYGapM);
  }

  .rodimus-complex-front .wrap-horizontal {
    padding: var(--brick-space-x1);
  }
  .rodimus-complex-front .wrap-horizontal-space.solo-group {
    padding: 0;
  }
}
