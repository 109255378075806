.optimus-background-custom-sport .promote-first {
  gap: var(--brick-space-x3);
}

.optimus-background-custom-sport .wrap-horizontal {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--brick-space-x3);
}
.optimus-background-custom-sport .wrap-default {
  display: grid;
  grid-auto-flow: row;
  gap: var(--brick-space-x2);
}
.optimus-background-custom-sport .carouselcontent {
  height: 100%;
}

.optimus-background-custom-sport {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--brick-space-x3);
  background-color: var(--brick-colors-baseNeutral00);
  color: var(--brick-colors-baseFg);
  margin: 0 0 var(--brick-space-deckYGapM) 0;
  padding: var(--brick-space-x3);
}

.rodimus-complex-front.default.optimus-background-custom-sport > header,
.rodimus-complex-front.list-horizontal.optimus-background-custom-sport
  > header {
  padding-bottom: 0;
  background: none;
}

.rodimus-complex-front.optimus-background-custom-sport .wrap-horizontal {
  padding-top: 0;
}

.rodimus-complex-front.optimus-background-custom-sport > header h2 {
  background-color: var(--brick-colors-baseNeutral00);
  color: var(--brick-colors-baseFg);
}

@media screen and (min-width: 533px) {
  .optimus-background-custom-sport .wrap-default {
    grid-auto-flow: column;
  }

  .optimus-background-custom-sport {
    padding-left: var(--brick-space-x2);
    padding-right: var(--brick-space-x2);
    margin: 0 0 var(--brick-space-deckYGapL) 0;
  }
}
